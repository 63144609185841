import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import Navmenu from './NavMenu';
import MenuMobil from './MobilMenu/MenuMobil';



const HeaderNew1 = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <>
            <header className="lg:absolute relative top-5 right-0 w-full z-[11]">
                <div className="w-[90%] flex-col h-auto hidden lg:flex">
                    <div className="w-[80%] h-auto relative">
                        {rpdata?.simpleWidgets?.[3]?.activo ? null : (
                            <div className="ml-7 w-4/5 pl-14 rounded-b-3xl py-[14px] bg-[#1e1e1e] flex space-x-6 items-center justify-center relative z-[50] inverted-border-radius ">
                                <Navmenu colorText="text-black " />
                            </div>

                        )}
                    </div>
                    <div className="w-[40%] h-[45vh] flex items-center justify-center pl-32">
                        <div>
                            <img
                                src={`${rpdata?.dbPrincipal?.logo}`}
                                alt="Logo Company"
                                width={350}
                                height={350}
                                className="object-cover w-[80%]"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex lg:hidden justify-center w-full">
                    <div className="w-[75%] h-auto relative">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt='logo'
                            loading='lazy'
                            className='w-[90%] mx-auto md:ml-16'
                        />
                    </div>
                    <div className="w-[35%] flex items-center justify-center">
                    <MenuMobil/>
                    </div>
                </div>
            </header >
        </>
    )
}

export default HeaderNew1